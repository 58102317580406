<template>
  <div class="filterView">
    <div class="cell-contianer" v-for="(item, index) in items" :key="index">
      <div v-if="index < limitCount" class="flex-row cell">
        <div class="title">{{ item.title }}:</div>
        <div class="flex-row child-container">
          <div
            v-show="j < maxChilds"
            :class="['child', selectsObj[item.key] === child.id ? 'child-on' : '']"
            v-for="(child, j) in item.children"
            :key="j"
            @click="selectCell(item, child)"
          >
            {{ index === 1 ? (child.status == 0 ?  child.name:'') : child.name }}
          </div>
          <div v-if="index === 1 &&  item.children.length > 20" @click="foldBtnClick" class="showMore">
            <span>{{ isOpening ? "收起" : "全部展开" }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//  项目申报 组件
export default {
  name: "policySupportFilterView",
  data() {
    return {
      // 显示全部部门
      maxChilds: 20,
      isOpening: false,
      limitCount: 10,
      selectsObj: {}, // 选择的key值，用于传回父类
      items: [
        {
          id: 0,
          title: "选择区划",
          key: "regionCode",
          children: [
            { id: "", name: "不限" },
            { id: "新安街道", name: "新安街道" },
            { id: "西乡街道", name: "西乡街道" },
            { id: "航城街道", name: "航城街道" },
            { id: "福永街道", name: "福永街道" },
            { id: "福海街道", name: "福海街道" },
            { id: "沙井街道", name: "沙井街道" },
            { id: "新桥街道", name: "新桥街道" },
            { id: "松岗街道", name: "松岗街道" },
            { id: "燕罗街道", name: "燕罗街道" },
            { id: "石岩街道", name: "石岩街道" },
          ],
        },

        {
          id: 1,
          title: "主管部门",
          key: "deptCode",
          children: [
            { id: 0, name: "不限" },
            // { id: 1, name: "文化广电旅游体育局" },
            // { id: 2, name: "人力资源和社会保障局" },
            // { id: 3, name: "市场监督管理局" },
            // { id: 4, name: "发展和改革局" },
            // { id: 5, name: "经济和科技促进局" },
          ],
        },
        {
          id: 2,
          title: "项目年度",
          key: "year",
          children: [
            { name: "不限" },
			{ id: 2023, name: "2023年" },
			{ id: 2022, name: "2022年" },
            { id: 2021, name: "2021年" },
            { id: 2020, name: "2020年" },
            { id: 2019, name: "2019年" },
            { id: 2018, name: "2018年" },
            { id: 2017, name: "2017年" },
          ],
        },
      ],
    };
  },
  created() {
    this.loadRegion();
    this.loadDeptList();
    // this.loadBasicInfoList();
  },
  methods: {
    // 主管部门
    loadDeptList() {
			let param = {
			  deptType:"1328"
			}
      this.$httpApi
        .get("/dev-api/system/dept/list", param)
        .then((res) => {
          console.log("dept list:", res);
          if (res.code == 200) {
            let result = [{ id: 0,status:"0", name: "不限" }];
            let list = res.rows;
            for (const i in list) {
              if (Object.hasOwnProperty.call(list, i)) {
                const element = list[i];
				element.id = element.deptId;
				element.name = element.deptName;
				result.push(element);
              }
            }
            this.$set(this.items, 1, {
              title: "主管部门",
              key: "deptCode",
              children: result,
            });
            this.initSelect();
          }
        })
        .catch((err) => {});
    },
    // 支持领域
    // loadBasicInfoList() {
    //   this.$httpApi.get("/dev-api/basicinfo/type/list", {}).then((res) => {
    //     console.log("basicinfo list:", res);
    //     if (res.code == 200) {
    //       let result = [{ id: 0, name: "不限" }];
    //       let list = res.rows;
    //       for (const i in list) {
    //         if (Object.hasOwnProperty.call(list, i)) {
    //           const element = list[i];
    //           result.push({
    //             id: element.itemTypeCode,
    //             name: element.typeName,
    //           });
    //         }
    //       }
    //       this.$set(this.items, 5, {
    //         title: "支持领域",
    //         key: "typeCode",
    //         children: result,
    //       });
    //       this.initSelect();
    //     }
    //   });
    // },

    // 区划
    loadRegion() {
      this.$httpApi
        .get("/dev-api/cfginfo/region/cascaderTreeList", {})
        .then((res) => {
          console.log("cascaderTreeList:", res);
          if (res.code == 200) {
            let rowsItem = res.rows[0];
            // 添加的第一个是区
            let result = [
              {
                id: '', //传区就相当于不限
                name: "漯河市",
                // id: rowsItem.id,
                // name: rowsItem.label,
              },
            ];
            let list = rowsItem.children;
            for (const i in list) {
              if (Object.hasOwnProperty.call(list, i)) {
                const element = list[i];
                result.push({
                  id: element.id,
                  name: element.label,
                });
              }
            }
            this.$set(this.items, 0, {
              title: "选择区划",
              key: "regionCode",
              children: result,
            });
            this.initSelect();
            // console.log("result:", this.items);
          }
        })
        .catch((err) => {});
    },

    initSelect() {
      for (const i in this.items) {
        const item = this.items[i];
        if (item.children && item.children.length > 0) {
          // 默认都选中第0个
          this.selectsObj[item.key] = item.children[0].id;
        }
      }
    },
    selectCell(item, child) {
      this.selectsObj[item.key] = child.id;
      this.$forceUpdate();
      let data = {};
      for (const key in this.selectsObj) {
        if (Object.hasOwnProperty.call(this.selectsObj, key)) {
          const value = this.selectsObj[key];
          if (value != 0) {
            // 第一列 不限的就不传
            data[key] = value;
          }
        }
      }
      console.log("value", data);
      this.$emit("changeSelect", data);
    },
    foldBtnClick() {
      this.isOpening = !this.isOpening;
      if (this.isOpening) {
        this.maxChilds = 1008611;
      } else {
        this.maxChilds = 20;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.filterView {
  padding: 20px 0;
  width: 100%;
}
.cell {
  padding: 6px 0;
  color: #888888;
  font-size: 13px;
  font-family: "Microsoft YaHei";
  border-bottom: 1px dotted #bbbbbb;
  font-weight: 500;
  .title {
    margin-right: 30px;
    color: #000;
    font-size: 15px;
    flex-shrink: 0;
  }
  .child-container {
    flex-wrap: wrap;
    .child {
      margin-right: 16px;
      cursor: pointer;
      flex-shrink: 0;
      padding: 8px 0;
    }
    .child-on {
      color: rgba(45, 129, 255, 1);
    }
  }
}
.fold-btn {
  padding: 8px 0;
  //   border-radius: 8px;
  background-color: #f1f1f1;
  color: rgba(45, 129, 255, 1);
  font-size: 14px;
  text-align: center;
  margin-top: 15px;
}
.showMore {
  padding: 0 5px;
  border: 1px solid rgba(45, 129, 255, 1);
  border-radius: 5px;
  color: rgba(45, 129, 255, 1);
}
</style>
