<template>
  <!-- 公开公示 -->
  <div class="notice-container">
    <!-- 图片 -->
    <div class="banner-size">
      <div class="banner-limit banner-info">
        <span class="banner-fMax">扶持政策</span>
      </div>
    </div>
    <!-- 内容-套 -->
    <div ref="policySupport">
      <!-- <FilterView @changeSelect="changeSelect"></FilterView> -->
      <!-- 筛选 -->
      <div class="banner-limit  mb-2">
        <FilterView @changeSelect="changeSelect"></FilterView>
      </div>
      <!-- 主要内容 -->
      <div class="banner-limit policy-body" style="margin-top: -20px">
        <!-- 搜索 + 列表 -->
        <div class="policy-body-left">
          <div>
            <SectionHeader
                :isNotEvolve="true"
                title="扶持政策"
                :totalSize="totalSize"
                placeholder="请输入政策名称"
                @clickButton="searchByKeyword"
              ></SectionHeader>
          </div>
          <div style="margin-top: -30px;">
            <PublicForm class="mt-20" :lists="dataList" :itemType="1"></PublicForm>
          </div>

        </div>
        <!-- 政策解读之类 -->
        <div class="policy-body-right">
          <PolicyRight></PolicyRight>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FilterView from "@/components/policySupportFilterView";
import PublicForm from "@/components/policySupportForm"; // 公开公示表单
import SectionHeader from "@/components/sectionHeader"; //扶持组件
import { Loading } from "element-ui";
import PolicyRight from "@/components/policyRight";
export default {
  components: {
    PublicForm,
    FilterView,
    SectionHeader,
	PolicyRight,
  },
  data() {
    return {
      // 标题信息
      title: {},
      // 项目政策数据
      dataList: null,
      totalSize: 0,
	  areaid:'',
	  deptid:'',
	  year:"",
	  name:null,
    };
  },
  mounted() {
    this.getPublicList();
  },
  methods: {
    // readCount
    getRandomNumberByRange(start, end) {
      return Math.floor(Math.random() * (end - start) + start);
    },
    todo(val) {
      let that = this;
      this.$router.push({
        path: "/policySupport/detail/" + val.id,
      });
    },
    getPublicList() {
      // type 1项目政策 2通知公告 3公开公示
      let loadingpolicySupport = Loading.service({
        target: this.$refs.policySupport,
      });
      let params = {
        pageNum: 1,
        pageSize: 10,
        type: 1,
		pubdateQuery:this.year,
		areaid:this.areaid,
		deptid:this.deptid,
		name:this.name
      };
	  console.log(params)
      this.$httpApi
        .get(`/dev-api/document/list`, params)
        .then((res) => {
          console.log("项目政策列表:", res);
          if (res.code === 200) {
            loadingpolicySupport.close();
            this.dataList = res.rows;
            if (this.dataList != null) {
              this.totalSize = this.dataList.length;
            }
          }
        })
        .catch((err) => {
          loadingpolicySupport.close();
          this.$message({
            message: "网络异常，请稍后重试",
            type: "error",
          });
        });
    },
    changeSelect(e) {
      console.log("changeSelect,index>>>", e);
	  this.areaid = e.regionCode
	  this.deptid = e.deptCode
	  this.year = e.year
	  this.getPublicList();
    },
    /**
     * 根据关键字查询
     * @param data
     */
    searchByKeyword(data) {
		this.name = data,
		this.getPublicList()
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/com/theme.scss";

.notice-container {
  min-height: 650px;

  .banner-size {
    background: url("../../assets/images/icon_banner_declare.png");
  }

  .banner-info {
    // width: 100%;
    height: 100%;
    display: flex;
    // justify-content: flex-start;
    align-items: center;

    .banner-fMax {
      font-size: 40px;
    }

    .banner-other {
      margin-left: 20px;
      font-size: 20px;
    }
  }

  .public-announcement {
    // background: red;
    // width: 100%;
    // height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
  }
}

/* 标题 */
.el-table__header-wrapper {
  .is-leaf {
    background: $bc-lightGrey;
  }
}

/* 内容 */
.el-table__body-wrapper {
  /* 状态栏 */
  // .el-table_1_column_1 {
  //     color: $color-danger !important;
  // }
  /* 横向属性 */
  .el-table__row {
    // background-color: #333;
    height: 60px;
    cursor: pointer;

    &:hover {
      // height: 70px;
      // font-size: $font-size18;
      font-weight: bold;

      .el-table_1_column_2 {
        color: $color-primary !important;
      }
    }
  }
}

.text-red {
  color: $color-danger;
}

/* 分页器 */
.el-pagination {
  // background-color: red;
  margin-top: 40px;
  align-self: flex-end;
}
.policy-body{
	width: 1200px;
  // background: red;
	// margin: 0 auto;
	display: flex;
  flex-flow: row nowrap;
	justify-content: space-between;
	.policy-body-left {
		// width: 90%;
    // padding-right: 40px;
	}
	.policy-body-right {
		// padding-left: 60px;
		margin-top: 10px;
    // background: chocolate;
	}
}
</style>
