<template>
  <el-table :data="lists" style="width: 100%" @row-click="todo">
    <el-table-column
      prop="name"
      label="政策名称"
      show-overflow-tooltip
      min-width="580"
      align="left"
    >
    </el-table-column>
   <el-table-column prop="deptname" label="主管部门" width="120" show-overflow-tooltip align="left">
    </el-table-column>
<!--    <el-table-column prop="areaname" label="所属区域" width="100" align="center">
    </el-table-column> -->
<!--    <el-table-column prop="viewcount" label="浏览量" width="80" align="center">
    </el-table-column> -->
    <el-table-column prop="policypubdate" label="发布日期" width="120" align="right">
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  name: "publicForm",
  props: {
    lists: {
      type: Array,
      default: function () {
        return [];
      },
    },
    //关注需要传此类型
    itemType: {
      type: Number,
      default: function () {
        return 0;
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    todo(val) {
      let that = this;
      console.log("点击,转跳", val);
      // this.$router.push({
      //   path: "/notice/detail/" + val.id + "?type=" + that.itemType,
      // });
			let { href } = this.$router.resolve({
			  path: "/notice/detail/" + val.id + "?type=" + this.itemType,
			});
			window.open(href, "_blank");
    },
    /* 暴露组件方法 */
  },
};
</script>

<style lang="scss">
@import "@/styles/com/theme.scss";
/* 标题 */
.el-table__header-wrapper {
  .is-leaf {
    background: $bc-lightGrey;
  }
}
/* 内容 */
.el-table__body-wrapper {
  /* 状态栏 */
  // .el-table_1_column_1 {
  //     color: $color-danger !important;
  // }
  /* 横向属性 */
  .el-table__row {
    height: 60px;
    cursor: pointer;
    &:hover {
      // height: 70px;
      // font-size: $font-size18;
      font-weight: bold;
      .el-table_1_column_1 {
        color: $color-primary !important;
      }
      .el-table_1_column_3 {
        color: $color-primary !important;
      }
      .el-table_1_column_4 {
        color: $color-primary !important;
      }
      .el-table_1_column_5 {
        color: $color-primary !important;
      }
    }
  }
}
.text-red {
  color: $color-danger;
}
.showStatu {
  color: $color-danger;
}
</style>
