<template>
	<div class="guideRight">
		<img class="item-img" src="../assets/images/policyInterpretation_title.png" />
		<div class="zhengcejiedu">政策解读</div>
		<div style="padding: 0 20px;">
			<div v-for="(item,index) in items" :key="index" class="info-cell pointer" @click="toDet(item)">
				<div class="info-title">
					{{getTypeInfo(item.type)}}
				</div>
				<span class="info-value" v-bind:title="item.name" >
					{{item.name}}
				</span>
			</div>
		</div>

		<img class="else-img" src="../assets/images/policyInterpretation_bottom.png" />
		<img class="else-img" src="../assets/images/policyInterpretation_bottom2.png" />
	</div>
</template>

<script>
	import util from "@/utils/util";
	export default {

		props: {
			isPlolicy: {
				type: String,
				default: function() {
					return "0"
				}
			},
			deptObject: {
				type: Object,
				default: function() {
					return null
				}
			},

		},
		data() {
			return {
				// 政策解读形式（1：图片 2：图文 3：文字 4：视频）
				items: [],
			};
		},
		mounted() {
			this.getItems()
		},
		methods: {
			getItems() {
				let params = {
					pageNum: 1,
					pageSize: 10,
				};
				this.$httpApi.get(`/dev-api/policyexplain/list`, params).then((res) => {
						console.log("res:", res);
						if (res.code == 200) {
							let list = res.rows;
							list.forEach((element) => {
								//  可能有多张图片 用逗号隔开
								let banners = element.barnnerimg.split(",");
								element.banner =
									this.$httpApi.baseUrl +
									"/dev-api/fileManageApi/common/fileView/" +
									banners[0];
								element.createTime = util.dateFormat(
									"YYYY-MM-DD",
									element.createTime
								);
							});
							console.log("政策解读：", list);
							this.items = list;
						}
					})
					.catch((err) => {

					});
			},
			/* 转跳到详细的文字信息 */
			toDet(item) {
				// alert("详细信息")
				// var myItem = {
				// 	name:"暂无数据",
				// 	createTime:"暂无数据",
				// 	content:'<p><img src="https://www.baoan.gov.cn/img/0/140/140624/8297517.jpg" alt="8238093.jpg"></p>',
				// }
				// this.$router.push({
				// 	name: "PolicyInterpretationDetail",
				// 	params: {
				// 		item: item,
				// 	},
				// });
				
				var { href } = this.$router.resolve({
					// name: "PolicyInterpretationDetail",
					path: "/policyInterpretation/detail",
					query:item,
				});

				window.open(href, "_blank");
			},
			// 政策解读形式（1：图片 2：图文 3：文字 4：视频）
			getTypeInfo(type) {
				switch (type) {
					case '0':
						return '图片解读';
						break;
					case '1':
						return '图文解读';
						break;
					case '2':
						return '文字解读';
						break;
					case '3':
						return '视频解读';
						break;
					default:
						return ''
						break;
				}
			}
		},
	};
</script>

<style lang="scss" scoped>
	.guideRight {
		display: flex;
		flex-flow: column nowrap;
		justify-content: flex-start;
		// align-items: flex-start;
		// background: red;
	}
	.zhengcejiedu {
		color: white;
		font-size: 20px;
		padding: 30px 20px;
		margin-top: -90px;
		text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
		font-family: Microsoft YaHei;
		font-weight: 700;
		color: #ffffff;
		font-size: 24px;
		letter-spacing: 2px;
		line-height: 22px;
	}

	.item-img {
		width: 350px;
		height: 100px;
		margin-top: 20px;
	}

	.else-img {
		width: 350px;
		height: 120px;
		margin-top: 20px;
	}

	.info-cell {
		display: flex;
		width: 310px;
		padding: 20px 0px;
		border-bottom: 2px dotted #9A9A9A;

		.info-title {
			width: 65px;
			font-family: Microsoft YaHei;
			color: #2d81ff;
			font-size: 14px;
			line-height: 22px;
		}

		.info-value {
			width: 255px;
			margin-left: 10px;
			font-family:Microsoft YaHei;
			color:#404040;
			font-size:14px;
			line-height:22px;
			text-align: left;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.info-value:hover {
		}
	}
</style>
